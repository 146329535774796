import { createContext, useContext } from "react"

export const SideNavContext = createContext<{
  sideNavOpen: boolean
  toggleSideNav: React.Dispatch<React.SetStateAction<boolean>>
}>({
  sideNavOpen: false,
  toggleSideNav: (value) => value,
})

export const useSideNavContext = () => useContext(SideNavContext)
