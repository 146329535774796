import { init as initFullStory } from "@fullstory/browser"
import { QueryClient, QueryClientProvider } from "@tanstack/react-query"
import { ReactQueryDevtools } from "@tanstack/react-query-devtools"
import { RouterProvider, createRouter } from "@tanstack/react-router"
import React from "react"
import ReactDOM from "react-dom/client"

import { AuthProvider, useAuth } from "./contexts/auth"
import { routeTree } from "./routeTree.gen"

const FULLSTORY_ID = import.meta.env.VITE_FS_ORG_ID ?? ""

export const router = createRouter({
  routeTree,
  defaultPreload: false,
  context: {
    auth: undefined!, // This will be set after we wrap the app in an AuthProvider
  },
})

// Register things for typesafety
declare module "@tanstack/react-router" {
  interface Register {
    router: typeof router
  }

  interface StaticDataRouteOption {
    title?: string
  }
}

function InnerApp() {
  React.useEffect(() => {
    if (window.location.hostname !== "localhost") {
      initFullStory({
        orgId: FULLSTORY_ID,
        cookieDomain: window.location.hostname,
      })
    }
  }, [])
  const auth = useAuth()
  return <RouterProvider router={router} context={{ auth }} />
}

function App() {
  return (
    <AuthProvider>
      <InnerApp />
    </AuthProvider>
  )
}

const rootElement = document.getElementById("root")!

if (!rootElement.innerHTML) {
  const root = ReactDOM.createRoot(rootElement)

  const queryClient = new QueryClient()
  queryClient.setDefaultOptions({
    queries: {
      placeholderData: (prev: any) => prev,
    },
  })

  root.render(
    <React.StrictMode>
      <QueryClientProvider client={queryClient}>
        <App />
        <ReactQueryDevtools
          initialIsOpen={false}
          buttonPosition="bottom-left"
        />
      </QueryClientProvider>
    </React.StrictMode>,
  )
}
